import { useState } from 'react';

//vendor
import ReactPlayer from 'react-player/lazy';

// components
import { Container } from 'components/Grid';
import {
  MirandaSecondaryButton,
  MirandaHollowSecondaryButton,
} from '@/components/Button/BaseButton';

//icons
import { IconClose } from '@loadsmart/icons';
import IconPlay from '@/public/images/carrier-tms/video-hero/icon-play.svg';

// styles
import {
  LifeLoadVideoWrapper,
  Title,
  MediaWrapper,
  VideoWrapper,
  ClosingIcon,
  PlayingIcon,
  Thumbnail,
  Video,
  CTAWrapper,
} from './LifeLoadVideo.styles';

export function LifeLoadVideo() {
  const [playVideo, setPlayVideo] = useState(false);

  return (
    <LifeLoadVideoWrapper>
      <Container>
        <Title>Reinventing the future of freight operations</Title>

        <MediaWrapper>
          <VideoWrapper className={playVideo ? `active` : ``}>
            <ClosingIcon
              onClick={() => {
                setPlayVideo(false);
              }}
            >
              <IconClose />
            </ClosingIcon>
            {playVideo && (
              <ReactPlayer
                className="react-player"
                url="https://www.youtube.com/watch?v=TPaNcx_NowY?rel=0"
                controls
                playing={playVideo}
                width="100%"
                height="100%"
              />
            )}
          </VideoWrapper>
          <Thumbnail
            className={playVideo ? `inactive` : ``}
            onClick={() => {
              setPlayVideo(true);
            }}
            role="button"
          >
            <Video
              autoPlay
              playsinline
              muted
              loop
              preload="auto"
              poster="/images/home/thumb/life-load.png"
            >
              <source src="/videos/home/bg_life-load.webm" type="video/webm" />
            </Video>

            <PlayingIcon>
              <IconPlay />
              <span>Play video</span>
            </PlayingIcon>
          </Thumbnail>
        </MediaWrapper>
      </Container>
      <CTAWrapper>
        <MirandaSecondaryButton as="a" href="/shipper/#hubspot-form">
          Request a Demo
        </MirandaSecondaryButton>
        <MirandaHollowSecondaryButton
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href="https://lp.loadsmart.com/case-studies"
        >
          Explore our Case Studies
        </MirandaHollowSecondaryButton>
      </CTAWrapper>
    </LifeLoadVideoWrapper>
  );
}
