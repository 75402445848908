import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const LifeLoadVideoWrapper = styled.section`
  padding: 0 0 80px;
  background-color: rgb(${getToken('color-background-tertiary')});
  text-align: center;

  ${screen.lg} {
    padding: 2px 0 80px;
  }

  ${screen.xxlg} {
    padding: 15px 0 88px;
  }
`;

export const Title = styled.h2`
  margin: 0px;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  font-weight: 700;
  font-size: 26px;
  line-height: 39px;
  text-align: center;

  ${screen.md} {
    font-size: 38px;
    line-height: 47.5px;
  }

  ${screen.lg} {
    br {
      display: none;
    }
  }
`;

export const CTAWrapper = styled.div`
  display: flex;
  padding: 48px 0 0;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;

  ${screen.md} {
    gap: 32px;
    flex-direction: row;
  }
`;

export const MediaWrapper = styled.div`
  position: relative;
  margin: 48px auto 0;
  height: 182px;
  width: 100%;
  position: relative;

  ${screen.md} {
    align-self: center;
    height: 356px;
    width: 656px;
  }

  ${screen.lg} {
    height: 412px;
    width: 760px;
  }

  ${screen.xxlg} {
    height: 586px;
    width: 1080px;
  }
`;

export const VideoWrapper = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: relative;
  display: none;

  &.active {
    display: block;
  }

  iframe {
    border-radius: 4px;
    filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));
  }

  ${screen.md} {
    align-self: center;

    iframe {
      border-radius: 8px;
    }
  }
`;

export const ClosingIcon = styled.button`
  width: 36px;
  height: 36px;

  position: absolute;
  top: -22px;
  right: -22px;
  z-index: 20;

  padding: 10px;
  background: rgba(255, 255, 255, 1);
  border-radius: 100px;
  border: none;
  cursor: pointer;
  filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));

  opacity: 0.85;

  &:hover {
    opacity: 1;
  }

  ${screen.md} {
    top: -28px;
    right: -28px;

    width: 54px;
    height: 54px;
  }
  ${screen.lg} {
    width: 62px;
    height: 62px;
  }
  ${screen.xxlg} {
    width: 75px;
    height: 75px;
  }
`;

export const PlayingIcon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  min-width: 116px;
  height: auto;
  z-index: 2;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 200px;
  filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));
  transition: all 0.1s ease-in-out;
  gap: 10px;

  background: rgba(100, 100, 100, 0.4);
  opacity: 0.7;
  bottom: 20px;
  right: 50%;

  transform: translateX(50%);

  span {
    display: flex;
    color: rgb(${getToken('color-text-inverted')});
    font-family: ${getToken('font-family-default')};
    font-weight: ${getToken('font-weight-bold')};

    text-align: center;
    font-size: 14px;
    line-height: 21px;
  }

  svg {
    border: 1px solid rgb(${getToken('color-text-inverted')});

    max-width: 24px;
    height: 24px;
    filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));

    padding: 4px 4px 4px 8px;
    border-radius: 18px;
  }

  ${screen.md} {
    bottom: 40px;
    right: 55px;

    transform: none;
  }

  ${screen.xxlg} {
    bottom: 50px;
    right: 65px;
  }
`;

export const Thumbnail = styled.button`
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  background: none;

  &:hover {
    video,
    picture {
      opacity: 0.9;
    }
    div {
      opacity: 1;
      background: rgba(80, 80, 80, 0.6);
      transition: all 0.1s ease-in-out;

      ${screen.md} {
        right: 35px;
      }

      ${screen.xxlg} {
        right: 45px;
      }
    }
  }

  &.inactive {
    display: none;
  }

  video,
  picture {
    source,
    img {
      border-radius: 16px;
    }
  }
`;

export const Video = styled.video`
  box-sizing: border-box;
  margin: auto;
  max-width: 100%;
  height: auto;
  border-radius: ${getToken('border-radius-m')};

  img,
  source {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: auto;
    filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));
  }
`;
