import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

import { MirandaButton } from '@/components/Button/BaseButton';

export const SectionWrapper = styled.section`
  padding: 40px 0;
  background-color: rgba(${getToken('color-background-tertiary')});
  text-align: center;

  ${screen.md} {
    padding: 50px 0;
  }

  ${screen.lg} {
    padding: 80px 0;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const TitleWrapper = styled.div`
  margin: 0 0 24px;

  ${screen.lg} {
    margin: 0 0 36px;
  }
`;

export const AwardsBadges = styled.aside`
  margin: 20px auto;

  svg {
    width: 46px;
    height: auto;
    margin: 0 10px;
  }

  ${screen.lg} {
    margin: 36px auto;

    svg {
      width: 94px;
      height: auto;
      margin: 0 18px;
    }
  }
`;

export const BadgeContainer = styled.div`
  display: inline-flex;
  margin: 6.5px;

  ${screen.lg} {
    margin: 18px;
  }
`;

export const Badge = styled.img`
  height: auto;
  width: 55px;

  ${screen.md} {
    width: 75px;
  }
  ${screen.lg} {
    width: 92px;
  }
`;

export const StyledButton = styled(MirandaButton)`
  padding: 16px;
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-text-inverted')});
  background-color: rgb(${getToken('color-primary-60')});
  font-weight: ${getToken('font-weight-bold')};
  margin-bottom: 50px;

  ${screen.md} {
    padding: 16px 52px;
  }

  ${screen.xxlg} {
    margin-bottom: 48px;
  }
`;

export const Title = styled.h2`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});
  text-align: center;

  font-size: 26px;
  line-height: 39px;
  margin: 0px auto 28px;

  ${screen.lg} {
    margin: 0px auto 36px;
    font-size: 38px;
    line-height: 47.5px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 60px;

    br {
      display: none;
    }
  }
`;

export const IntroText = styled.p`
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-text-primary')});
  font-size: 14px;
  line-height: 21px;
  margin: 0px auto;

  ${screen.lg} {
    font-size: 20px;
    line-height: 36px;
  }
`;

export const CTAWrapper = styled.div`
  display: flex;
  padding: 24px 0 0;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${screen.md} {
    gap: 32px;
    flex-direction: row;

    a:nth-child(1) {
      width: auto;
    }
  }

  ${screen.lg} {
    padding: 36px 0 0;
  }
`;

export const MediaWrapper = styled.div`
  position: relative;
  margin: auto;
  max-width: 315px;
  height: 177px;

  ${screen.md} {
    max-width: 690px;
    height: 387px;
  }

  ${screen.lg} {
    max-width: 826px;
    height: 464px;
  }

  ${screen.xxlg} {
    max-width: 1086px;
    height: 610px;
  }
`;

export const VideoWrapper = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: relative;
  display: none;

  &.active {
    display: block;
  }

  iframe {
    border-radius: 4px;
    filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));
  }

  ${screen.md} {
    align-self: center;

    iframe {
      border-radius: 8px;
    }
  }
`;

export const ClosingIcon = styled.button`
  width: 36px;
  height: 36px;

  position: absolute;
  top: -22px;
  right: -22px;
  z-index: 20;

  padding: 10px;
  background: rgba(255, 255, 255, 1);
  border-radius: 100px;
  border: none;
  cursor: pointer;
  filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));

  opacity: 0.85;

  &:hover {
    opacity: 1;
  }

  ${screen.md} {
    top: -28px;
    right: -28px;

    width: 54px;
    height: 54px;
  }
  ${screen.lg} {
    width: 62px;
    height: 62px;
  }
  ${screen.xxlg} {
    width: 75px;
    height: 75px;
  }
`;

export const Video = styled.video`
  box-sizing: border-box;
  margin: auto;
  max-width: 100%;
  height: auto;
  border-radius: ${getToken('border-radius-m')};

  img,
  source {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: auto;
    filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));
  }
`;

export const PlayingIcon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  min-width: 116px;
  height: auto;
  z-index: 2;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 200px;
  filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));
  transition: all 0.1s ease-in-out;
  gap: 10px;

  background: rgba(100, 100, 100, 0.4);
  opacity: 0.7;
  bottom: 20px;
  right: 50%;

  transform: translateX(50%);

  span {
    display: flex;
    color: rgb(${getToken('color-text-inverted')});
    font-family: ${getToken('font-family-default')};
    font-weight: ${getToken('font-weight-bold')};

    text-align: center;
    font-size: 14px;
    line-height: 21px;
  }

  svg {
    border: 1px solid rgb(${getToken('color-text-inverted')});

    max-width: 24px;
    height: 24px;
    filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));

    padding: 4px 4px 4px 8px;
    border-radius: 18px;
  }

  ${screen.md} {
    bottom: 40px;
    right: 55px;

    transform: none;
  }

  ${screen.xxlg} {
    bottom: 50px;
    right: 65px;
  }
`;

export const Thumbnail = styled.button`
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  background: none;

  &:hover {
    picture {
      opacity: 0.9;
    }
    div {
      opacity: 0.9;
    }
  }

  &.inactive {
    display: none;
  }
`;

export const BrandWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin: auto;
  color: rgb(${getToken('color-primary-100')});
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-regular')};

  font-size: 27px;

  ${screen.md} {
    font-size: 32.4px;
  }

  ${screen.lg} {
    font-size: 44px;
  }
`;

export const LogoWrapper = styled.div`
  height: 25px;
  margin-bottom: 24px;

  ${screen.md} {
    height: 30px;
  }

  ${screen.lg} {
    height: 41px;
    margin-bottom: 36px;
  }

  svg,
  path {
    height: 100%;
    color: rgb(${getToken('color-primary-100')});
    fill: rgb(${getToken('color-primary-100')});
  }
`;
