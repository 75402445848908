//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '/containers-m/';
import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';

// context containers
import {
  Hero,
  FloatHeroBox,
  IntroductionText,
  FeaturesCarousel,
  SquaresMenu,
  TMSIntegration,
  SidePanels,
  TeamHero,
  FloatTeamHeroBox,
} from './containers/index';

//styles
import { PageWrapper } from './Shipper.styles';

//setup info
import { tmsIntegrationOptions } from '@/vendor/HubspotFormIntegration';

function Shipper() {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <Hero />
        <FloatHeroBox />
        <IntroductionText />
        <FeaturesCarousel />
        <SquaresMenu />
        <TMSIntegration />
        <SidePanels />
        <TeamHero />
        <FloatTeamHeroBox />
        <HubspotForm {...tmsIntegrationOptions}>
          <HubspotFormTitle>
            Talk to a Transportation Specialist
          </HubspotFormTitle>
          <HubspotFormDescription>
            Our team of industry experts and technicians are here to answer your
            questions.
          </HubspotFormDescription>
        </HubspotForm>
      </>
      <Footer />
    </PageWrapper>
  );
}

export default Shipper;
export { Shipper };
